export default [
  'offender',
  'housearrest',
  'parolee',
  'probation',
  'bail',
  'prisoner',
  'inmate',
  'furlough',
  'perpetrator',
  'convict',
  'suspect',
  'victim',
  'dementia',
  'mental',
  'others',
];
